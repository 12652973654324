import { getDay } from '@highloop-pkg/date-utils';
import * as Sentry from '@sentry/nextjs';
import Head from 'next/head';
import { useCallback, useEffect, useMemo } from 'react';
import useSWR from 'swr';
import { getCookie } from 'tiny-cookie';
import useToday from 'use-today';
import { config } from '../../config';
import { useTheme } from './atoms/theme';
import { ICalendar } from './interfaces/calendar';
import { IGoogleCalendar } from './interfaces/googleCalendar';
import { IProgress } from './interfaces/progress';
import { ITeam, ITeamMember } from './interfaces/team';
import { IToken } from './interfaces/token';
import { IUser } from './interfaces/user';
import { aresFetcher, client, getAuthHeaders } from './sdk/client';

export let getToken = async () => {
  let token = getCookie('token');
  if (!token) notLoggedInHandler();
  return token;
};

export let useBoot = () => {
  let { data, error, mutate } = useSWR<{
    user: IUser;
    teams: (ITeam & {
      calendars: ICalendar[];
      membership: ITeamMember;
    })[];
  }>(`/boot`, aresFetcher);

  let [_, setTheme] = useTheme();

  useEffect(() => {
    if (data?.user) {
      Sentry.setUser({
        id: data.user.id,
        email: data.user.email,
        name: data.user.name,
        photoUrl: data.user.photoUrl
      });

      setTheme(data.user.flags.theme);
      // @ts-ignore
      window.updateTheme();
    }
  }, [data?.user]);

  let createCalendar = async (teamId: string, data: { type: 'calendar' | 'board'; name: string }) => {
    let res = await client.post<{ calendar: ICalendar }>(
      `/team/${teamId}/calendar`,
      {
        name: data.name,
        type: data.type,
        isBoard: data.type === 'board'
      },
      {
        headers: await getAuthHeaders()
      }
    );

    await mutate();

    return res.data.calendar;
  };

  let calendars = useMemo(
    () =>
      data
        ? data.teams
            .filter((t) => !t.disabled)
            .map((t) => t.calendars)
            .flat()
        : [],
    [data]
  );

  return {
    user: data?.user,
    teams: data?.teams,
    calendars,
    revalidate: useCallback(() => mutate(), [mutate]),
    createCalendar,
    mutate,
    error,
    loading: !data && !error
  };
};

export let useProgress = () => {
  let today = useToday();
  let date = useMemo(() => (today ? getDay(today) : undefined), [today]);

  let { teams } = useBoot();

  let { data, error, mutate } = useSWR<{
    progress: {
      [key: string]: IProgress;
    };
  }>(date ? `/progress?date=${date}` : null, aresFetcher);

  useEffect(() => {
    mutate();
  }, [teams]);

  return {
    progress: data?.progress || {},
    error,
    loading: !data && !error
  };
};

export let useSessions = () => {
  let { data, error } = useSWR<{ sessions: IToken[] }>('/user/sessions', aresFetcher);

  return {
    sessions: data?.sessions || [],
    error,
    loading: !data && !error
  };
};

export let useGCal = () => {
  let { user } = useUser();

  let { data, error, mutate } = useSWR<{
    hasProTeams: boolean;
    connections: {
      id: string;
      setup: boolean;
      calendarIds: string[];
      calendars: IGoogleCalendar[];
      user: {
        id: string;
        email: string;
        name: string;
      };
    }[];
  }>('/user/gcal', aresFetcher);

  let setCalendarIDs = async (data: { id: string; calendarIds: string[] }) => {
    let res = await client.put<{ calendarIds: string[] }>('/user/gcal/calendarIds', data, {
      headers: await getAuthHeaders()
    });

    await mutate((d) => ({
      ...d,
      calendarIds: res.data.calendarIds
    }));
  };

  return {
    connections: data?.connections,
    hasProTeams: data?.hasProTeams,
    // googleUser: data?.user,
    // calendars: data?.calendars || [],
    // calendarIds: data?.calendarIds || [],
    // setup: data?.setup,
    error,
    setCalendarIDs,
    loading: !data && !error
  };
};

export let useUser = () => {
  let { user, error, loading, revalidate, mutate } = useBoot();

  let updateUser = async (data: { name?: string; email?: string; photoUrl?: string }) => {
    let res = await client.put<{ user: IUser }>('/user', data, {
      headers: await getAuthHeaders()
    });

    mutate((d) => ({
      ...d,
      user: {
        ...d.user,
        ...res.data.user
      }
    }));

    return res.data.user;
  };

  let updateDigest = async (data: { digestEnabled: boolean; digestDays: string[]; digestHour: number; digestTimezoneOffset: number }) => {
    let res = await client.put<{ user: IUser }>('/user/digest', data, {
      headers: await getAuthHeaders()
    });

    await mutate();

    return res.data.user;
  };

  return {
    user,
    error,
    loading,
    revalidate,
    updateDigest,
    updateUser
  };
};

export let useDigestEnabled = () => {
  let { data, error } = useSWR<{ enabled: boolean }>('/user/digest/check', aresFetcher);

  return {
    enabled: data?.enabled,
    error,
    loading: !data && !error
  };
};

export let useTeams = () => {
  let { teams, error, loading, revalidate } = useBoot();
  return { teams, error, loading, revalidate };
};

export let useNotLoggedIn = () => {
  useUser();
};

export let notLoggedInHandler = () => {
  if (process.env.NODE_ENV == 'development') return null;

  location.replace(config.auth + '/login?next=' + encodeURIComponent(location.protocol + '//' + location.host + location.pathname + location.search));
};

export let MustBeLoggedIn = () => {
  if (process.env.NODE_ENV == 'development') return null;

  useNotLoggedIn();

  return (
    <Head>
      <script
        dangerouslySetInnerHTML={{
          __html: `if (!document.cookie || document.cookie.indexOf('token=') === -1) {
            location.replace('${config.auth}/login?next=' + encodeURIComponent(location.protocol + '//' + location.host + location.pathname + location.search))
          } else {
            document.documentElement.classList.add('render')
          }`
        }}
      ></script>
    </Head>
  );
};
