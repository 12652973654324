import * as Sentry from '@sentry/nextjs';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { config } from '../../../config';
import { getToken, notLoggedInHandler } from '../useBoot';

let client = axios.create({ baseURL: config.api });
axiosRetry(client, { retries: 2 });

export let getAuthHeaders = async () => {
  let token = await getToken();
  if (!token) return {};

  return {
    Authorization: 'Bearer ' + token
  };
};

export let aresFetcher = async (url: string) =>
  client
    .get(url, {
      headers: await getAuthHeaders()
    })
    .then(r => r.data)
    .catch(err => {
      if (err?.response?.status == 401) {
        notLoggedInHandler();
      } else {
        Sentry.captureException(err);
        throw err;
      }
    });

export { client };
