import createLocalstorageState from '@weekrise/use-localstorage';

export let useTheme = createLocalstorageState('[weekrise]:settings:theme', 'system');

export let getTheme = () => useTheme.getState();

export let setTheme = (theme: string) => {
  useTheme.setState(theme);

  setTimeout(() => {
    (window as any).updateTheme();
  }, 60);
};
