import { VaporProvider } from '@vapor/ui';
import Head from 'next/head';
import Script from 'next/script';
import { SWRConfig } from 'swr';
import { MustBeLoggedIn } from '../state/useBoot';
import '../styles/global.css';
import '../styles/reset.css';
import '../styles/variables.css';

function MyApp({ Component, pageProps }) {
  return (
    <SWRConfig
      value={{
        shouldRetryOnError: false
      }}
    >
      <VaporProvider ssr>
        <Head>
          <title>Weekrise</title>
        </Head>

        {process.env.NODE_ENV == 'production' && (
          <>
            <Script src="https://version.weekrise.com/index.js" />
          </>
        )}

        <MustBeLoggedIn />

        <Component {...pageProps} />
      </VaporProvider>
    </SWRConfig>
  );
}

export default MyApp;
