export let config = {
  auth: '/auth',
  api: 'https://athena.weekrise.com',
  gcalApi: 'https://athena.weekrise.com/gcal/api',
  gcalRedirectUri: '/app',

  paddle:
    process.env.NODE_ENV == 'production'
      ? {
          vendorId: 152588,
          isSandbox: false,
          proMonthlyId: 781270,
          teamMonthlyId: 781271
        }
      : {
          vendorId: 6058,
          isSandbox: true,
          proMonthlyId: 28432,
          teamMonthlyId: 32790
        }
};

if (process.env.NODE_ENV === 'development') {
  config = {
    auth: 'http://localhost:3100',
    api: 'http://localhost:3102',
    gcalApi: 'http://127.0.0.1:8787/gcal/api',
    gcalRedirectUri: 'http://localhost:3001',

    paddle: config.paddle
  };
}
